const { get } = require('./ajax');

const searchCtr = _e('div', { className: 'search-container' });
const searchBox = _e('input', {
    type: 'search',
    name: 'Search',
    parent: searchCtr,
});
_e('button', {
    innerHTML: 'Search',
    title: 'Search',
    parent: searchCtr,
});
const results = _e('div', { className: 'search-results', parent: searchCtr });

results.style.display = 'none';

const nav = document.querySelector('nav');

const sib = nav.firstElementChild;
nav.insertBefore(searchCtr, sib);

let lastWords = '';

searchBox.addEventListener('change', handler);
searchBox.addEventListener('input', handler);

let searchTimeout = null;

function stageSearch(text) {
    lastWords = text;
    if (searchTimeout) {
        clearTimeout(searchTimeout);
        searchTimeout = null;
    }
    const url = `/search/${encodeURIComponent(text)}`;

    searchTimeout = setTimeout(() => {
        get(url).then((body) => {
            const entries = JSON.parse(body);
            results.innerHTML = '';

            const count =
                addResults('Shows', entries, 'gigs', results) +
                addResults('Artists', entries, 'artists', results) +
                addResults('Venues', entries, 'venues', results);

            if (!count) {
                results.innerHTML = 'No matches found';
            }

            showResultsBox();
        });
    }, 250);
}

function showResultsBox() {
    results.style.display = 'block';

    const boxPos = results.getBoundingClientRect();
    const winWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

    const winHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    const boxWidth = Math.min(400, winWidth - boxPos.x - 10);
    const boxHeight = Math.min(1000, winHeight - boxPos.y - 10);

    results.style.height = `${boxHeight}px`;
    results.style.width = `${boxWidth}px`;
}

function handler() {
    const text = searchBox.value.trim();

    if (text) {
        if (text !== lastWords) {
            stageSearch(text);
        }
    } else {
        dropSearchResults();
    }
}

function addResults(groupname, allEntries, key, container) {
    const entries = allEntries[key];

    if (entries.length > 0) {
        _e('h4', {
            className: 'search-header',
            content: groupname,
            parent: container,
        });

        entries.forEach((entry) => {
            const link = `/${key}/${entry.slug}`;
            const title = entry.name || entry.title;
            const a = _e('a', {
                href: link,
                content: title,
                className: 'search-result',
                parent: container,
            });

            if (entry.nice_day) {
                _e('span', {
                    parent: a,
                    content: entry.nice_day,
                    className: 'search-date',
                });
            }
        });
    }

    return entries.length;
}

function dropSearchResults() {
    results.innerHTML = '';
    results.style.display = 'none';
    lastWords = '';
}

function _e(tag, options) {
    const el = document.createElement(tag);

    if (options) {
        for (const key in options) {
            if (key === 'content') {
                el.appendChild(document.createTextNode(options.content));
            } else if (key === 'parent') {
                options.parent.appendChild(el);
            } else {
                el[key] = options[key];
            }
        }
    }

    return el;
}
